import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { Layout } from '../components/common'
import { MetaData } from '../components/common/meta'
import { navigate } from 'gatsby'

// Prism package for syntax highlighting
import { useEffect } from "react"
import Prism from "prismjs"

const Post = ({ data, location }) => {
    const post = data.ghostPost
    // make sure there is never a null slug string
    const postSlug = [post.primary_tag.slug] ? [post.primary_tag.slug].toString() : 'Information'
    const postTag = postSlug.split('-')[0]
    const fromSnip = location.pathname.replace(/[/]/g,'')
    const toSnip = fromSnip.replace(/^_/,'')
    const tagPage = `/tag/${postTag}-${toSnip}/`
    const checkStartsWith = () => {
        if (fromSnip.startsWith('_')) {
            navigate(tagPage)
        }
        return null
    }

    // Call the highlightAll() function to style code blocks
    useEffect(() => {
        Prism.highlightAll()
        checkStartsWith()
    })

    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="article"
            />

            <Helmet>
                <style type="text/css">{`${post.codeinjection_styles}`}</style>
            </Helmet>

            <Layout>

                {/* Show public tags */}
                <div className="container">

                    {post.tags && 
                        <div className="post-tag"> 

                            {/* eslint-disable-next-line */}
                            {post.tags.map((value) => {
                                if (value.visibility === `public`) {     

                                    // let name = value.name.replace(`.`,` . `)
                                    let name = value.name.indexOf(`.`) > -1 ? value.name.split(`.`).pop() : value.name
                                    return (
                                        <a className="post-tag__link"
                                            key={value.slug} 
                                            href={`/tag/` + value.slug}
                                            title={name}>{name}</a>
                                    )
                                }
                            })}
                        </div>
                    }
                </div>

                <div className="container">
					<article>
						{post.published_at_pretty && <div className="post-published"> {post.published_at_pretty} </div>}
                        <h2 className="page-title">{post.title}</h2>
                        <div 
                            className="load-external-scripts post-content"
                            dangerouslySetInnerHTML={{ __html: post.html}}
                        />
                    </article>
                </div>

                <div className="container"><br/></div>

            </Layout>
        </>
    )
}

Post.propTypes = {
    data: PropTypes.shape({
        ghostPost: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
            published_at_pretty: PropTypes.string.isRequired,
            tags: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string,
                })
            ),
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
}

export default Post

export const postQuery = graphql`
    query($slug: String!) {
        ghostPost(
            slug: { 
				eq: $slug 
			}
        ) {
            ...GhostPostFields
        }
    }
`
